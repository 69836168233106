<script setup lang="ts">
/********************
 * COMPOSITIONS      *
 ********************/
const getRouteBaseName = useRouteBaseName();
const route = useRoute();

/********************
 * REFS & VARS       *
 ********************/
const routeBaseName = computed(() => {
  return getRouteBaseName(route);
});

const pageClass = computed(() => {
  const snailCaseClass = routeBaseName.value?.split(/(?=[A-Z])/) || [''];
  return `page page--${snailCaseClass.join('-').toLowerCase()}`;
});
</script>

<template>
  <div class="flex flex-col">
    <CiHeaderAccount />
    <main
      class="mb-12 grow"
      :class="pageClass"
    >
      <slot />
    </main>
  </div>
</template>

<style></style>
