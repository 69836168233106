<script setup lang="ts">
/********************
 * COMPOSITIONS      *
 ********************/
const localePath = useLocalePath();
</script>

<template>
  <header class="ci-header-account mb-6 border-b border-gray-20 bg-white">
    <div class="container py-2">
      <div class="row justify-center">
        <div class="col-12 col-md-4 col-lg-auto flex justify-center">
          <nuxt-link :to="localePath({ name: 'index' })">
            <img
              alt="logo"
              class="max-w-[200px] px-0 py-2"
              height="39"
              loading="lazy"
              src="~/assets/svg/logo_coloured_horizontal.svg"
              width="200"
            />
          </nuxt-link>
        </div>
      </div>
    </div>
  </header>
</template>

<style></style>
